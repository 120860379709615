angular
    .module('annexaApp')
    .component('annexaBoxObjectBook',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book.html',
        controller: ['BooksFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService','DccumentsFactory', 'SecFactory', 'TableFilterFactory', 'SignLocalFactory', 
        	function (BooksFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, DccumentsFactory, SecFactory, TableFilterFactory, SignLocalFactory) {
            // region General
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.canEdit = false;
            vm.tabs = [];
            vm.alerts = [];

            // endregion


           this.$onInit = function() {
        	   vm.canEdit = ((vm.book && vm.book.state && _.contains(['DRAFT'],vm.book.state))?true:false);
        	   if(vm.canEdit){
        		   vm.canEdit = BooksFactory.havePermissionBookType(vm.book.bookType, 'update')
        	   }
           }

            // region book Methods
           $scope.goBack = function(){
               $state.transitionTo('annexa.books.list');
           };
            
            
           vm.updateBook = function(val, prop) {
				if(prop == 'name'){
					if(!val || val == '') {
						return $filter('translate')('global.books.view.requiredBookName');
					}
				}
				vm.book[prop] = val;
				
				RestService.update('./api/books/' + vm.book.id, vm.book).then(function (data) {
					vm.canEdit = ((vm.book && vm.book.state && _.contains(['DRAFT'],vm.book.state))?true:false);      
				}).catch(function (error) {
					console.error(error);
				});
           }
           
           $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
               if(args.id == 'modalASElementsBook') {
                   if(args.selected && args.selected.length > 0){
                	  if(args.selected[0].id){
                		  args.selected = $linq(args.selected).select("x => x.id").toArray();
                	   }
	                    BooksFactory.addElementsToBook(vm.book.id, args.selected).then(function(data) {
	                    	if(vm.book && vm.book.bookType && vm.book.bookType.bookElement){
	        		           	if(vm.book.bookType.bookElement == 'REGISTER'){
	        		           		$rootScope.$broadcast('reloadBookRegisterList',{});
	        		           	}else if(vm.book.bookType.bookElement == 'DOCUMENT'){
	        		           		$rootScope.$broadcast('reloadBookDocumentList',{});
	        		           	}else if(vm.book.bookType.bookElement == 'SESSION'){
	        		           		$rootScope.$broadcast('reloadBookSessionList',{});
	        		           	}else if(vm.book.bookType.bookElement == 'DOSSIER' ||  vm.book.bookType.bookElement == 'DOSSIER_DOCUMENT'){
	        		           		$rootScope.$broadcast('reloadBookDossierList',{});
	        		           	}else if(vm.book.bookType.bookElement == 'DECREE'){
	        		           		$rootScope.$broadcast('reloadBookDecreeList',{});
	        		           	}
	                    	}
	                    }).catch(function(error) {
	                    	DialogsFactory.error($filter('translate')('global.books.view.errorAddElement'), $filter('translate')('DIALOGS_ERROR_MSG'));  
	                    })         	
                   }
                   args.modal.close();
               }
           });
           
           vm.deleteElementToBook = function(id){
	           if(id){
                    BooksFactory.deleteElementToBook(vm.book.id, id).then(function(data) {
                    	if(vm.book && vm.book.bookType && vm.book.bookType.bookElement){
        		           	if(vm.book.bookType.bookElement == 'REGISTER'){
        		           		$rootScope.$broadcast('reloadBookRegisterList',{});
        		           	}else if(vm.book.bookType.bookElement == 'DOCUMENT'){
        		           		$rootScope.$broadcast('reloadBookDocumentList',{});
        		           	}else if(vm.book.bookType.bookElement == 'SESSION'){
        		           		$rootScope.$broadcast('reloadBookSessionList',{});
        		           	}else if(vm.book.bookType.bookElement == 'DOSSIER' ||  vm.book.bookType.bookElement == 'DOSSIER_DOCUMENT'){
        		           		$rootScope.$broadcast('reloadBookDossierList',{});
        		           	}else if(vm.book.bookType.bookElement == 'DECREE'){
        		           		$rootScope.$broadcast('reloadBookDecreeList',{});
        		           	}
                    	}
                    }).catch(function(error) {
                    	DialogsFactory.error($filter('translate')('global.books.view.errorDeleteElement'), $filter('translate')('DIALOGS_ERROR_MSG'));  
                    })         	
               }
           }
           
           vm.closingRequestBook = function(){
        	   DialogsFactory.confirm('global.books.view.closingRequestBook', 'global.books.view.closingRequestBookConfirm')
               .then(function (data) {
		            BooksFactory.changeState(vm.book.id, 'CLOSING_REQUEST').then(function(data) {
		            	if(data && data.state && data.state == 'CLOSING_REQUEST'){
		            		$state.reload();  
		            	}
		            }).catch(function(error) {
		            	DialogsFactory.error($filter('translate')('global.books.view.errorClosingRequest'), $filter('translate')('DIALOGS_ERROR_MSG'));  
		            })         	
	           }).catch(function (data) {
		               //Empty
		       });
           }
           
           vm.sendToSignBook = function(){
        	   DialogsFactory.confirm('global.documents.datatable.enviarFirmar', 'global.books.view.sendToSignConfirm')
               .then(function (data) {
            	   RestService.findOne('Document', vm.book.indexDocument.id).then(function(data) {
            		   SignLocalFactory.sendToSignModal(undefined, [JSOG.decode(data.data)]);
                   });
            	    
               }).catch(function (data) {
		               //Empty
		       });
          }
         
	      $scope.$on('SendToSignModalFinished', function (event) {
	    	  $state.reload();  
	      });
	      
          vm.reopenBook = function(){
        	   DialogsFactory.confirm('global.books.view.reopenBook', 'global.books.view.reopenBookConfirm')
               .then(function (data) {
		            BooksFactory.changeState(vm.book.id, 'DRAFT').then(function(data) {
		            	if(data && data.state && data.state == 'DRAFT'){
		            		$state.reload();  
		            	}
		            }).catch(function(error) {
						if(error && error.code && error.code.toUpperCase() == 'Template Exception'.toUpperCase()){
							var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.message)?'<br/><br/>'+error.message:'');
							DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
						}else{
			            	DialogsFactory.error($filter('translate')('global.books.view.errorReopenBook'), $filter('translate')('DIALOGS_ERROR_MSG'));
						}  
		            })    
               }).catch(function (data) {
		               //Empty
		       });
          }
          
           vm.addElementsToBookModal = function() {
	        	var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
	            
	           	var docStatusRender = function(data, type, full, meta) {
	                var content = '';
	
	                if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
	                    var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");
	
	                    if(status) {
	                        content += '<div class="text-center">';
	                        content += '    <span class="fa-stack" title="' + $filter('translate')('global.documentState.' + status.name) + '">';
	                        content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
	                        content += '    </span>';
	                        content += '</div>';
	                    }
	                }
	
	                return content;
	            }
	           	
	           	var renderEntryType = function(data, type, full, meta) {
                    var content = '';

                    if(data == 'INPUT') {
                        content += '<i class="fa fa-sign-in" title="{{ \'global.literals.input\' | translate }}"></i>';
                    } else {
                        content += '<i class="fa fa-sign-out" title="{{ \'global.literals.output\' | translate }}"></i></span>';
                    }

                    return content;
                }
	       	 
	            var sessionStatusRender = function(data, type, full, meta) {
	                var content = '';

	                if(SecFactory.sessionStates && SecFactory.sessionStates.length > 0) {
	                    var state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

	                    if(state) {
	                        content += '<div class="text-center">';
	                        content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
	                        content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
	                        content += '    </span>';
	                        content += '</div>';
	                    }
	                }

	                return content;
	            }
	            
	            var decStatusRender = function(data, type, full, meta) {
                    var content = '';
                    if(SecFactory.decreeStates && SecFactory.decreeStates.length > 0) {
                        var state = $linq(SecFactory.decreeStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");
                        if(state) {
                            content += '<div class="text-center">';
                            content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                            content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                            content += '    </span>';
                            content += '</div>';
                        }
                    }
                    return content;
                }
	            
	            var getModelFromTypeElement = function(alias){
	            	var model = undefined;
	            	if(alias && vm.book && vm.book.bookType && vm.book.bookType.bookTypeFilterElements){
	            		 var elementType = $linq(vm.book.bookType.bookTypeFilterElements).firstOrDefault(undefined,"x => x.alias == '"+alias+"'");
	            		 if(elementType && elementType.value){
	            			 model = elementType.value;
	            		 }
	            	}
	            	return model;
	            }
	            
	           	var advancedSearchModal = {size: 'modal-lg', multiple: true};
	           	if(vm.book && vm.book.bookType && vm.book.bookType.bookElement){
		           	if(vm.book.bookType.bookElement == 'REGISTER'){
		           		advancedSearchModal.title = "global.literals.advanced_search_registerEntry";
		           		advancedSearchModal.filter = [];
		           		var elementModelFromType = getModelFromTypeElement('entryType');
		           		advancedSearchModal.filter.push({ id: 'entryType', type: 'select', order: 1, label:'global.literals.regType',  dataType: 'LOCAL', data: GlobalDataFactory.entryTypes, addAll: true, nameProperty: 'name', preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('entryNumber');
		           		advancedSearchModal.filter.push({ id: 'entryNumber', type: 'text', order: 2, label:'global.literals.number', model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('extract');
		           		advancedSearchModal.filter.push({ id: 'extract', type: 'text', order: 3, label: 'global.literals.extract', model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('procedureName');
		           		advancedSearchModal.filter.push({ id: 'procedureName', type: 'text', order: 0, label: 'global.literals.procedure', callAux: true, userChoosen: ((elementModelFromType)?false:true), model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('dossierNumber');
		           		advancedSearchModal.filter.push({ id: 'dossierNumber', type: 'text', order: 1, label: 'global.literals.numExp', callAux: true, userChoosen: ((elementModelFromType)?false:true), model:elementModelFromType});
		           		var elementModelFromTypeFrom = getModelFromTypeElement('dataRegFrom');
		           		var elementModelFromTypeTo = getModelFromTypeElement('dataRegTo');
		           		if(elementModelFromTypeFrom || elementModelFromTypeTo){
		           			elementModelFromType = {"dataFrom":((elementModelFromTypeFrom)?new Date(elementModelFromTypeFrom):undefined), "dataTo":((elementModelFromTypeTo)?new Date(elementModelFromTypeTo):undefined)}; 
		           		}else{
		           			elementModelFromType = undefined;
		           		}
		           		advancedSearchModal.filter.push({ id: 'dataReg', type: 'dateRange', order: 2, label: 'global.literals.regFrom', callAux: true, userChoosen: false, model:elementModelFromType });
		           		elementModelFromTypeFrom = getModelFromTypeElement('dataPresFrom');
		           		elementModelFromTypeTo = getModelFromTypeElement('dataPresTo');
		           		if(elementModelFromTypeFrom || elementModelFromTypeTo){
		           			elementModelFromType = {"dataFrom":((elementModelFromTypeFrom)?new Date(elementModelFromTypeFrom):undefined), "dataTo":((elementModelFromTypeTo)?new Date(elementModelFromTypeTo):undefined)}; 
		           		}else{
		           			elementModelFromType = undefined;
		           		}
		           		advancedSearchModal.filter.push({ id: 'dataPres', type: 'dateRange', order: 3, label: 'global.literals.presFrom', callAux: true, userChoosen: false, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('entryDocumentType');
		           		advancedSearchModal.filter.push({ id: 'entryDocumentType', type: 'select', order: 4, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesInput, addAll: true, nameProperty: vm.languageColumn, userChoosen: ((elementModelFromType)?false:true), preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('entryClassification');
		           		advancedSearchModal.filter.push({ id: 'entryClassification', type: 'select', order: 5, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsInput, addAll: true, nameProperty: vm.languageColumn, userChoosen: ((elementModelFromType)?false:true), preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('representant');
		           		advancedSearchModal.filter.push({ id: 'representant', type: 'text', order: 6, label: 'global.literals.representant', callAux: true, userChoosen: ((elementModelFromType)?false:true), model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('section');
		           		if(elementModelFromType){
		           			var sectionElementModelFromType = $linq(GlobalDataFactory.sections).firstOrDefault(undefined, "x => x.id == "+elementModelFromType); 
		           			if(sectionElementModelFromType){
		           				elementModelFromType = {"$selected":{"id":sectionElementModelFromType.id, "title":sectionElementModelFromType[vm.languageColumn]}};
		           			}else{
		           				elementModelFromType = undefined;
		           			}
		           		}
		           		advancedSearchModal.filter.push({ id: 'section', type: 'select-tree', order: 13, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: vm.languageColumn, userChoosen: ((elementModelFromType)?false:true), model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('diligenceProfile');
		           		if(elementModelFromType){
		           			elementModelFromType = JSON.parse(elementModelFromType);
		           		}
		           		advancedSearchModal.filter.push({ id: 'diligenceProfile', type: 'select-multiple', order: 14, label: 'global.literals.profile', dataType: 'LOCAL', data: new SortedArray(GlobalDataFactory.allProfiles,vm.languageColumn).sort(), addAll: true, nameProperty: vm.languageColumn, callAux: true, userChoosen: ((elementModelFromType)?false:true), preFilter:elementModelFromType});
		           		if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
		           			elementModelFromType = getModelFromTypeElement('registerEntryOffice');
		           			advancedSearchModal.filter.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.register_office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: vm.languageColumn, preFilter:elementModelFromType});
		                }
		           		advancedSearchModal.tableDefinition = {
		           				id: 'tableAdvancedSearchElementsBook',
                                origin: 'reg',
                                objectType: 'RegisterEntry',
                                sortName: 'entryNumber', 
                                sort: [[2, 'asc']],
                                columns: [
                                    { id: 'entryType', width: '40px', title: $filter('translate')('global.literals.type'), render: renderEntryType },
                                    { id: 'entryNumber', width: '110px', title: $filter('translate')('global.literals.number') },
                                    { id: 'registerDate', width: '90px', column: new DateTimeColumn($filter,'global.reg.datatable.registerDate') },
                                    { id: 'presentationDate', width: '90px', column: new DateColumn($filter,'global.literals.presFrom') },
                                    { id: 'registerEntryChannel.'+ vm.languageColumn, width: '60px', title: $filter('translate')('global.literals.channel') },
                                    { id: 'extract', title: $filter('translate')('global.literals.extract'), sortable: false }
                                ],
                                noLoading: true, 
                                skipFirstSearch: true,
			                    filterCallFunc:function () {
			                        var filterCall = angular.copy(TableFilterFactory.getFilterCall(advancedSearchModal.filter));
			                        if(filterCall && filterCall.entryType && filterCall.entryType.id){
			                       	 filterCall.entryType = filterCall.entryType.id;
			                        }
			                        return filterCall;
			                    },
                                callAuxToAdd: { 
                                	isAdvanced:true,
                                	bookId : vm.book.id,
    	                        	bookModule : 'BOOKS_REGISTER',
    	                        	bookSearch: 'ADVANCED_SEARCH'
                                },
                                allIds: BooksFactory.getAllRegisterIds
		           		};
		           	}else if(vm.book.bookType.bookElement == 'DOCUMENT'){
		           		var documentTypes = [];
		           		if(vm.book.bookType.attachDocumentTypes && vm.book.bookType.attachDocumentTypes.length > 0){
		           			documentTypes = $linq(vm.book.bookType.attachDocumentTypes).select("x => x.documentType").toArray();
		           		}else{
		           			documentTypes = $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray();
		           		}
		           		advancedSearchModal.title = "global.literals.advanced_search_documents";
		           		advancedSearchModal.filter= [];
		           		var elementModelFromType = getModelFromTypeElement('code');
		           		advancedSearchModal.filter.push({ id: 'code', type: 'text', order: 0, label: 'global.literals.code', model:elementModelFromType });
		           		elementModelFromType = getModelFromTypeElement('name');
		           		advancedSearchModal.filter.push({ id: 'name', type: 'text', order: 1, label: 'global.literals.title', model:elementModelFromType });
		           		advancedSearchModal.filter.push({ id: 'internalDoc', type: 'checkbox', order: 2, label: 'global.documents.internalDocument', callAux: true, model: true });
		           		elementModelFromType = getModelFromTypeElement('template');
		           		advancedSearchModal.filter.push({ id: 'template', type: 'select', order: 0, label: 'global.literals.template', dataType: 'LOCAL', data: $linq(GlobalDataFactory.templates).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: 'description', userChoosen: ((elementModelFromType)?false:true), preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('section');
		           		advancedSearchModal.filter.push({ id: 'section', type: 'select', order: 1, label: 'global.literals.section', dataType: 'LOCAL', data: $linq(GlobalDataFactory.sections).orderBy("x => x." + vm.languageColumn).toArray(), addAll: true, nameProperty: vm.languageColumn, userChoosen: ((elementModelFromType)?false:true), preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('type');
		           		advancedSearchModal.filter.push({ id: 'type', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: documentTypes, addAll: true, nameProperty: vm.languageColumn, userChoosen: ((elementModelFromType)?false:true), preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('origin');
		           		advancedSearchModal.filter.push({ id: 'origin', type: 'select', order: 3, label: 'global.literals.module', dataType: 'LOCAL', data: GlobalDataFactory.docModules, addAll: true, nameProperty: vm.languageColumn, callAux: true, userChoosen: ((elementModelFromType)?false:true), preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('createUser');
		           		advancedSearchModal.filter.push({ id: 'createUser', type: 'text', order: 4, label: 'global.literals.user', callAux: true, userChoosen: ((elementModelFromType)?false:true), model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('meta');
		           		advancedSearchModal.filter.push({ id: 'meta', type: 'text', order: 5, label: 'global.literals.meta', callAux: true, userChoosen: ((elementModelFromType)?false:true), model:elementModelFromType});
		           		var elementModelFromTypeFrom = getModelFromTypeElement('modifyDdateFrom');
		           		var elementModelFromTypeTo = getModelFromTypeElement('modifyDdateTo');
		           		if(elementModelFromTypeFrom || elementModelFromTypeTo){
		           			elementModelFromType = {"dataFrom":((elementModelFromTypeFrom)?new Date(elementModelFromTypeFrom):undefined), "dataTo":((elementModelFromTypeTo)?new Date(elementModelFromTypeTo):undefined)}; 
		           		}else{
		           			elementModelFromType = undefined;
		           		}
		           		advancedSearchModal.filter.push({ id: 'modifyDdate', type: 'dateRange', order: 6, label: 'global.literals.modified', callAux: true, userChoosen: false, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('dossierNumber');
		           		advancedSearchModal.filter.push({ id: 'dossierNumber', type: 'text', order: 7, label: 'global.literals.numExp', callAux: true, userChoosen: ((elementModelFromType)?false:true), model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('docStatus');
		           		advancedSearchModal.filter.push({ id: 'docStatus', type: 'select', order: 8, label: 'global.literals.status', callAux: true, dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), addAll: true, nameProperty: 'nameLiteral', userChoosen: ((elementModelFromType)?false:true), preFilter:elementModelFromType});
		           		advancedSearchModal.filter.push({ id: 'related', type: 'checkbox', order: 9, label: 'global.literals.showRelateds', callAux: true, preFilter:true, negated:false});
		           		advancedSearchModal.tableDefinition = {
	           				id: 'tableAdvancedSearchElementsBook',
		                    origin: 'doc',
		                    objectType: 'Document',
		                    sortName: 'modifyDate',
		                    sort: [[5, 'desc']],
		                    columns: [
		                    	{ id: 'docStatus', width: '10%', title: $filter('translate')('global.literals.status'), render: docStatusRender, sortable: false },
		                        { id: 'type', width: '10%', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
		                        { id: 'id', width: '10%', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.code) { code = full.code.substr((full.code.indexOf(full.id) - 2), full.id.toString().length + 2); } code;') },
		                        { id: 'id', column: new UIRouterStateColumnNoLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'name')  },
		                        { id: 'createUser', width: '20%', column: new UserColumn($filter, 'global.literals.user') },
		                        { id: 'modifyDate', width: '20%', column: new DateTimeColumn($filter, 'global.literals.modifyDate') }
		                    ], 
		                    noLoading: true,
		                    skipFirstSearch: true,
		                    callAuxToAdd: { 
		                    	bookId : vm.book.id,
	                        	bookModule: 'BOOKS_DOCUMENTS',
	                        	bookSearch: 'ADVANCED_SEARCH',
		                    },
                            allIds: BooksFactory.getAllDocumentIds
		           		};
		           		if(vm.book.bookType.attachDocumentTypes && vm.book.bookType.attachDocumentTypes.length > 0){
		           			advancedSearchModal.tableDefinition.callAuxToAdd.docTypesBook = $linq(documentTypes).select("x => x.id").toArray(); 
		           		}
		           	}else if(vm.book.bookType.bookElement == 'SESSION'){
		           		advancedSearchModal.title = "global.literals.advanced_search";
		           		advancedSearchModal.filter= [];
		           		var elementModelFromType = getModelFromTypeElement('state');
		           		if(elementModelFromType){
		           			elementModelFromType = JSON.parse(elementModelFromType);
		           		}
		           		advancedSearchModal.filter.push({ id: 'state', type: 'select-multiple', order: 3, label: 'global.literals.state', dataType: 'LOCAL', data: SecFactory.sessionStates, addAll: true, nameProperty: 'name', callAux: true, preFilter:elementModelFromType });
		           		elementModelFromType = getModelFromTypeElement('organ');
		           		advancedSearchModal.filter.push({ id: 'organ', type: 'select', order: 0, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: GlobalDataFactory.organs, addAll: true, nameProperty: vm.languageColumn, callAux: true, preFilter:elementModelFromType});
		           		var elementModelFromTypeFrom = getModelFromTypeElement('sessionDateFirstConveneFrom');
		           		var elementModelFromTypeTo = getModelFromTypeElement('sessionDateFirstConveneTo');
		           		if(elementModelFromTypeFrom || elementModelFromTypeTo){
		           			elementModelFromType = {"dataFrom":((elementModelFromTypeFrom)?new Date(elementModelFromTypeFrom):undefined), "dataTo":((elementModelFromTypeTo)?new Date(elementModelFromTypeTo):undefined)}; 
		           		}else{
		           			elementModelFromType = undefined;
		           		}
		           		advancedSearchModal.filter.push({ id: 'sessionDateFirstConvene', type: 'dateRange', order: 10, label: 'global.sec.literals.sessionDate', callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('sessionType');
		           		advancedSearchModal.filter.push({ id: 'sessionType', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: GlobalDataFactory.sessionTypes, addAll: true, nameProperty: vm.languageColumn, callAux: true, preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('sessionNumber');
		           		advancedSearchModal.filter.push({ id: 'sessionNumber', type: 'text', order: 1, label: 'global.sec.literals.sessionNumber', model:elementModelFromType});
		           		advancedSearchModal.tableDefinition = {
		           			 id: 'tableAdvancedSearchElementsBook',
		                     origin: 'sec',
		                     objectType: 'Session',
		                     sortName: 'sessionDateFirstConvene',
		                     sort: [[4,'desc']],
		                     columns: [
		                    	 { id: 'createdDate', visible: false },
		                         { id: 'state', width: '5%', title: $filter('translate')('global.literals.status'), render: sessionStatusRender, sortable: false},
		                         { id: 'organ', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.organ', vm.languageColumn) },
		                         { id: 'sessionType', width: '15%', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.type', vm.languageColumn) },
		                         { id: 'sessionDateFirstConvene', width: '20%', column: new DateTimeColumn($filter, 'global.sec.literals.sessionDate')},
		                         { id: 'sessionNumber', width: '25%', column: new TextColumn($filter, 'global.sec.literals.sessionNumber')}
		                     ],
		                     noLoading: true,
		                     skipFirstSearch: true,
		                     callAuxToAdd: {
		                    	 bookId : vm.book.id,
		                         bookModule : 'BOOKS_SESSIONS',
		                         bookSearch: 'ADVANCED_SEARCH'
		                     },
                             allIds: BooksFactory.getAllSessionIds
		           		}
		           	}else if(vm.book.bookType.bookElement == 'DOSSIER' ||  vm.book.bookType.bookElement == 'DOSSIER_DOCUMENT'){
		           		advancedSearchModal.title = "global.literals.advanced_search";
		           		advancedSearchModal.filter= [];
		           		var elementModelFromType = getModelFromTypeElement('dossierNumber');
		           		advancedSearchModal.filter.push({ id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('family');
		           		if(elementModelFromType){
		           			var sectionElementModelFromType = $linq(GlobalDataFactory.familyProcedures).firstOrDefault(undefined, "x => x.id == "+elementModelFromType); 
		           			if(sectionElementModelFromType){
		           				elementModelFromType = {"$selected":{"id":sectionElementModelFromType.id, "title":sectionElementModelFromType[vm.languageColumn]}};
		           			}else{
		           				elementModelFromType = undefined;
		           			}
		           		}
		           		advancedSearchModal.filter.push({ id: 'family', type: 'select-tree', order: 1, label: 'global.literals.family', dataType: 'LOCAL', data: GlobalDataFactory.familyProcedures, addAll: true, nameProperty: vm.languageColumn, callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('procedure');
		           		advancedSearchModal.filter.push({ id: 'procedure', type: 'text', order: 0, label: 'global.literals.procedure', userChoosen: ((elementModelFromType)?false:true), callAux: true, model:elementModelFromType });
		           		elementModelFromType = getModelFromTypeElement('subject');
		           		advancedSearchModal.filter.push({ id: 'subject', type: 'text', order: 1, label: 'global.literals.subject', userChoosen: ((elementModelFromType)?false:true), callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('thirds');
		           		advancedSearchModal.filter.push({ id: 'thirds', type: 'text', order: 2, label: 'global.literals.thirdsInt', userChoosen: ((elementModelFromType)?false:true), callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('thirds_identification');
		           		advancedSearchModal.filter.push({ id: 'thirds_identification', type: 'text', order: 3, label: 'global.literals.thirds_identification', userChoosen: ((elementModelFromType)?false:true), callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('language');
		           		advancedSearchModal.filter.push({ id: vm.languageColumn, type: 'text', order: 4, label: 'global.literals.transaction', userChoosen: ((elementModelFromType)?false:true), callAux: true, model:elementModelFromType });
		           		elementModelFromType = getModelFromTypeElement('custom_fields_search');
		           		advancedSearchModal.filter.push({ id: 'custom_fields_search', type: 'text', order: 5, label: 'global.literals.customFields', userChoosen: ((elementModelFromType)?false:true), callAux: true, model:elementModelFromType });
		           		var elementModelFromTypeFrom = getModelFromTypeElement('datesFrom');
		           		var elementModelFromTypeTo = getModelFromTypeElement('datesTo');
		           		if(elementModelFromTypeFrom || elementModelFromTypeTo){
		           			elementModelFromType = {"dataFrom":((elementModelFromTypeFrom)?new Date(elementModelFromTypeFrom):undefined), "dataTo":((elementModelFromTypeTo)?new Date(elementModelFromTypeTo):undefined)}; 
		           		}else{
		           			elementModelFromType = undefined;
		           		}
		           		advancedSearchModal.filter.push({ id: 'dates', type: 'dateRange', order: 6, label: 'global.delegate.startDate', userChoosen: false, callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('creator');
		           		advancedSearchModal.filter.push({ id: 'creator', type: 'text', order: 7, label: 'global.literals.creator', userChoosen: ((elementModelFromType)?false:true), callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('register_entry');
		           		advancedSearchModal.filter.push({ id: 'register_entry', type: 'text', order: 8, label: 'global.literals.register_entry_INI', userChoosen: ((elementModelFromType)?false:true), callAux: true, model:elementModelFromType});
		           		advancedSearchModal.tableDefinition = {
		           			 id: 'tableAdvancedSearchElementsBook',
		                     origin: 'tram',
		                     objectType: 'Dossier',
		                     sortName: 'dossierNumber',
		                     sort: [[1, 'asc']],
		                     columns: [
		                         { id: 'dossierNumber', title: $filter('translate')('global.literals.code') },
		                         { id: 'procedure.' + vm.languageColumn, title: $filter('translate')('global.literals.procedure') },
		                         { id: 'subject', title: $filter('translate')('global.literals.dossier') }
		                     ],
		                     noLoading: true,
		                     skipFirstSearch: true,
		                     callAuxToAdd: { 
		                    	 filterOrigin: 'dossierAdvancedView',
		                    	 bookId : vm.book.id,
		                         bookModule : 'BOOKS_DOSSIERS',
		                         bookSearch: 'ADVANCED_SEARCH'
		                     },
                             allIds: BooksFactory.getAllDossierIds
		           		};
		           	}else if(vm.book.bookType.bookElement == 'DECREE'){
		           		advancedSearchModal.title = "global.literals.advanced_search";
		           		advancedSearchModal.filter= [];
		           		vm.filterDecreeBook = [
	                    	{ id: 'state', type: 'select', order: 2, label: 'global.literals.state', dataType: 'LOCAL', data: vm.decreeStates, addAll: true, nameProperty: 'name', callAux: true},
	                    	{ id: 'decreeNumber', type: 'text', order: 0, label: 'global.sec.literals.decreeNumberAbrv'},
	                    	{ id: 'proposalType', type: 'select', order: 11, label: 'global.sec.literals.proposalType', data:vm.proposalTypes , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
	                        { id: 'proposalNumber', type: 'text', order: 1, label: 'global.sec.literals.proposalNumber', callAux: true },
	                        { id: 'decreeDate', type: 'dateRange', order: 2, label: 'global.sec.literals.decreeDate', callAux: true },
	                        { id: 'extract', type: 'text', order: 3, label: 'global.literals.extract' }
	                    ];
		           		
		           		var decreeStates = angular.copy(SecFactory.decreeStates);
		                decreeStates.unshift({id: '', icon: 'minus', style: 'text-black', name: $filter('translate')('global.literals.all'), orderStatus: 0});
		                var proposalTypes = angular.copy(GlobalDataFactory.proposalTypes);
		                proposalTypes = $linq(proposalTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
		                proposalTypes.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
		                
		           		var elementModelFromType = getModelFromTypeElement('state');
		           		advancedSearchModal.filter.push({ id: 'state', type: 'select', order: 0, label: 'global.literals.state', dataType: 'LOCAL', data: decreeStates, addAll: true, nameProperty: 'name', callAux: true, preFilter:elementModelFromType });
		           		elementModelFromType = getModelFromTypeElement('decreeNumber');
		           		advancedSearchModal.filter.push({ id: 'decreeNumber', type: 'text', order: 1, label: 'global.sec.literals.decreeNumberAbrv', model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('proposalType');
		           		advancedSearchModal.filter.push({ id: 'proposalType', type: 'select', order: 2, label: 'global.sec.literals.proposalType', dataType: 'LOCAL', data: proposalTypes, addAll: true, nameProperty: vm.languageColumn, callAux: true, preFilter:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('proposalNumber');
		           		advancedSearchModal.filter.push({ id: 'proposalNumber', type: 'text', order: 3, label: 'global.sec.literals.proposalNumber',  callAux: true,model:elementModelFromType});
		           		var elementModelFromTypeFrom = getModelFromTypeElement('decreeDateFrom');
		           		var elementModelFromTypeTo = getModelFromTypeElement('decreeDateTo');
		           		if(elementModelFromTypeFrom || elementModelFromTypeTo){
		           			elementModelFromType = {"dataFrom":((elementModelFromTypeFrom)?new Date(elementModelFromTypeFrom):undefined), "dataTo":((elementModelFromTypeTo)?new Date(elementModelFromTypeTo):undefined)}; 
		           		}else{
		           			elementModelFromType = undefined;
		           		}
		           		advancedSearchModal.filter.push({ id: 'decreeDate', type: 'dateRange', order: 4, label: 'global.sec.literals.decreeDate', callAux: true, model:elementModelFromType});
		           		elementModelFromType = getModelFromTypeElement('extract');
		           		advancedSearchModal.filter.push({ id: 'extract', type: 'text', order: 5, label: 'global.literals.extract', model:elementModelFromType});
		           		advancedSearchModal.tableDefinition = {
		           			 id: 'tableAdvancedSearchElementsBook',
		                     origin: 'sec',
		                     objectType: 'Decree',
		                     sortName: 'decreeDate', 
		                     sort: [[4,'desc']],
		                     columns: [
		                    	 { id: 'status', width: '1%', title: '', render: decStatusRender, sortable: false },
		                         { id: 'proposal.proposalType', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.proposalType', Language.getActiveColumn()) },
		                     	 { id: 'decreeNumber', column: new TextColumn($filter, 'global.sec.literals.decreeNumberAbrv') },
		                     	 { id: 'decreeDate', width: '12%', column:  new DateColumn($filter, 'global.sec.literals.decreeDate') },
		                     	 { id: 'extract', column:  new TextColumn($filter, 'global.literals.extract') },
		                         { id: 'proposal.proposalNumber', column: new TextColumn($filter, 'global.sec.literals.proposalNumber') }
		                     ],
		                     noLoading: true,
		                     skipFirstSearch: true,
		                     callAuxToAdd: {
		                    	 bookId : vm.book.id,
		                         bookModule : 'BOOKS_DECREES',
		                         bookSearch: 'ADVANCED_SEARCH'
		                     },
                             allIds: BooksFactory.getAllDecreeIds
		           		}
		           	}
		           	if(_.contains(['REGISTER','DOCUMENT','SESSION','DOSSIER_DOCUMENT','DOSSIER', 'DECREE'],vm.book.bookType.bookElement)){
		           		AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASElementsBook', advancedSearchModal);
		           	}
	           	}
           };
           
           
           
           // endregion
        }],
        bindings: {
            redirectToList: '=',
            book: '=?',
            preloadBoxes: '=?',
        }
    })
    .component('annexaBoxObjectBookHeader',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book-header.html',
        require: {
        	annexaBoxObjectBook: '^^annexaBoxObjectBook'
        },
        controller:['$rootScope', '$filter', 'Language', 'BooksFactory', '$scope', function($rootScope, $filter, Language, BooksFactory, $scope) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.state = $linq(BooksFactory.bookStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
        	
            this.$onInit = function() {
            	if(vm.annexaBoxObjectBook && vm.annexaBoxObjectBook.book){
            		if(vm.annexaBoxObjectBook.book.state){
                		vm.state = $linq(BooksFactory.bookStates).singleOrDefault(undefined, "x => x.id == '"+vm.annexaBoxObjectBook.book.state+"'");
               		}
            	}              
            }
        }]
    })
    .component('annexaBoxObjectBookDetail',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book-detail.html',
        require: {
        	annexaBoxObjectBook: '^^annexaBoxObjectBook'
        },
        controller:['$rootScope', '$filter', 'Language', 'BooksFactory', '$scope', 'AnnexaPermissionsFactory', function($rootScope, $filter, Language, BooksFactory, $scope, AnnexaPermissionsFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.bookTypes = new SortedArray(BooksFactory.bookTypes, vm.languageColumn).sort();
            vm.fieldsInfBasicDefinition = [];
            vm.fieldsElementscDefinition = [];
            this.$onInit = function() {
            	vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'bookType', fieldType: 'select', data: vm.bookTypes, colClass: 'col-sm-12', required: true, label: 'global.books.list.bookType', isReadOnly:false});
                vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'observations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.literals.observations', isReadOnly:vm.annexaBoxObjectBook.canEdit});
                if(vm.annexaBoxObjectBook && vm.annexaBoxObjectBook.book && vm.annexaBoxObjectBook.book.state == "ERROR"){
                	vm.fieldsInfBasicDefinition.push({ type: 'field', id: 'errorMessage', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.books.view.errorMessage', isReadOnly:false});
                }
                				
                
                if(vm.annexaBoxObjectBook && vm.annexaBoxObjectBook.book && vm.annexaBoxObjectBook.book.indexDocument && vm.annexaBoxObjectBook.book.indexDocument.name){
	            	if(AnnexaPermissionsFactory.havePermission(['view_documents']) && vm.annexaBoxObjectBook.book.indexDocument.docStatus != 'ARCHIVED'){
	            		vm.fieldsElementscDefinition.push({ type: 'field', id: 'indexDocument', fieldType: 'component', componentType: 'annexa-link-value', colClass: 'col-sm-12',
		                    data: {
		                         link: 'annexa.doc.documents.view({code: '+vm.annexaBoxObjectBook.book.indexDocument.id+'})',
		                         label: vm.annexaBoxObjectBook.book.indexDocument.name,
		                         labelAux:'global.books.view.indexDocument',
		                         showLabelAux:true
		                    },
		                    defaultValue: []
		                });
	            	}else{
	            		vm.fieldsElementscDefinition.push({ type: 'field', id: 'eniDossierDocument', fieldType: 'component', componentType: 'annexa-label-value', colClass: 'col-sm-12', label: 'global.books.view.indexDocument',
                            data: {
                                value: vm.annexaBoxObjectBook.book.indexDocument.name,
                                type: 'text',
                                rowClass: 'm-b-xs'
                            }
                        });
	            	}
            		
            	}
            	if(vm.annexaBoxObjectBook && vm.annexaBoxObjectBook.book && vm.annexaBoxObjectBook.book.dossier && vm.annexaBoxObjectBook.book.dossier.dossierNumber){
            		vm.canProcess = function () {
                        var haveProcessProfile = false;
                        if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                            var dossierProcessProfiles = [];
                            if(vm.annexaBoxObjectBook.book.dossier && vm.annexaBoxObjectBook.book.dossier.dossierTransactions) {
                                _.forEach(vm.annexaBoxObjectBook.book.dossier.dossierTransactions, function (dt) {
                                    if(dt.processProfiles) {
                                        _.forEach(dt.processProfiles, function (item) {
                                            dossierProcessProfiles.push(item.profile.id);
                                        })
                                    }
                                });
                            }
                            var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();

                            haveProcessProfile = intersect.length > 0;

                        }

                        return haveProcessProfile;
                    }

                    vm.getDossierTransaction = function () {
                        var dossierTransaction;
                        if(AnnexaPermissionsFactory.havePermission('process_dossier')) {
                            var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
                            if(vm.annexaBoxObjectBook.book.dossier && vm.annexaBoxObjectBook.book.dossier.dossierTransactions) {
                                _.forEach(vm.annexaBoxObjectBook.book.dossier.dossierTransactions, function (dt) {
                                    if (dt.actual && dt.processProfiles) {
                                        var dossierProcessProfiles = [];
                                        _.forEach(dt.processProfiles, function (item) {
                                            dossierProcessProfiles.push(item.profile.id);
                                        })
                                        var intersect = $linq(userProfiles).intersect(dossierProcessProfiles, "(x,y) => x == y").toArray();
                                        if (intersect.length > 0) {
                                            dossierTransaction = dt;
                                        }
                                    }
                                });
                            }
                        }

                        return dossierTransaction;
                    }

                    vm.canView = function () {
                        var haveViewProfile = false;

                        if(AnnexaPermissionsFactory.havePermission(['view_dossier', 'see_all_dossier'])) {
                        	haveViewProfile = true;
                        }

                        return haveViewProfile;
                    }

                    vm.redirect = function () {
                    	var link ='';
                    	if(vm.annexaBoxObjectBook.book.dossier.dossierStatus != 'ARCHIVED' && vm.annexaBoxObjectBook.book.dossier.dossierStatus != 'REJECTED' && vm.annexaBoxObjectBook.book.dossier.dossierStatus != 'TRANSFER'){
	                        if(vm.canProcess()){
	                            var dossierTransaction = vm.getDossierTransaction();
	                            if(dossierTransaction) {
	                                link = 'annexa.tram.pending.viewdossier({dossier: '+vm.annexaBoxObjectBook.book.dossier.id+', dossierTransaction: '+dossierTransaction.id+'})';
	                            }else{
	                            	 link = 'annexa.tram.view.viewdossier({ dossier:'+vm.annexaBoxObjectBook.book.dossier.id+', dossierTransaction:-1})';
	                            }
	                        }else if(vm.canView()){
	                        	 link = 'annexa.tram.view.viewdossier({ dossier:'+vm.annexaBoxObjectBook.book.dossier.id+', dossierTransaction:-1})';
	                        }
                    	}
                        return link;
                    }
            		var redirect = vm.redirect();
            		if(redirect && redirect != ''){
	            		vm.fieldsElementscDefinition.push({ type: 'field', id: 'dossier', fieldType: 'component', componentType: 'annexa-link-value', colClass: 'col-sm-12',
		                    data: {
		                         link: redirect,
		                         label: vm.annexaBoxObjectBook.book.dossier.dossierNumber,
		                         labelAux:'global.books.view.dossier',
		                         showLabelAux:true
		                    },
		                    defaultValue: []
		                });
            		}else{
            			vm.fieldsElementscDefinition.push({ type: 'field', id: 'dossier', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.books.view.dossier', className: 'col-lg-12',
                            data: {
                                value: vm.annexaBoxObjectBook.book.dossier.dossierNumber,
                                type: 'text',
                                rowClass: 'm-b-xs'
                            }
                        });
            		}
            		if(vm.annexaBoxObjectBook && vm.annexaBoxObjectBook.book && vm.annexaBoxObjectBook.book.eniDossierDocument && vm.annexaBoxObjectBook.book.eniDossierDocument.name){
                		if(AnnexaPermissionsFactory.havePermission(['view_documents']) && ( vm.canProcess() || vm.canView()) && vm.annexaBoxObjectBook.book.eniDossierDocument.docStatus != 'ARCHIVED'){
    	            		vm.fieldsElementscDefinition.push({ type: 'field', id: 'eniDossierDocument', fieldType: 'component', componentType: 'annexa-link-value', colClass: 'col-sm-12',
    		                    data: {
    		                         link: 'annexa.doc.documents.view({code: '+vm.annexaBoxObjectBook.book.eniDossierDocument.id+'})',
    		                         label: vm.annexaBoxObjectBook.book.eniDossierDocument.name,
    		                         labelAux:'global.books.view.eniDossierDocument',
    		                         showLabelAux:true
    		                    },
    		                    defaultValue: []
    		                });
                		}else{
                			vm.fieldsElementscDefinition.push({ type: 'field', id: 'eniDossierDocument', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.books.view.eniDossierDocument', className: 'col-lg-12',
                                data: {
                                    value: vm.annexaBoxObjectBook.book.eniDossierDocument.name,
                                    type: 'text',
                                    rowClass: 'm-b-xs'
                                }
                            });
                		}
                	}
            	}
            }
        }]
    })
    .component('annexaBoxObjectBookElementList',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book-element-list.html',
        require: {
        	annexaBoxObjectBook: '^^annexaBoxObjectBook'
        },
        controller:['$rootScope', '$filter', 'Language', 'BooksFactory', '$scope', function($rootScope, $filter, Language, BooksFactory, $scope) {
            var vm = this;
            
            this.$onInit = function() {
            	vm.languageColumn = Language.getActiveColumn();
            	vm.title = "";
            	if(vm.annexaBoxObjectBook.book.bookType.bookElement == 'REGISTER'){
            		vm.title = 'global.books.view.relatedRegisters';
	           	}else if(vm.annexaBoxObjectBook.book.bookType.bookElement == 'DOCUMENT'){
	           		vm.title = 'global.books.view.relatedDocuments';
	           	}else if(vm.annexaBoxObjectBook.book.bookType.bookElement == 'SESSION'){
	           		vm.title = 'global.books.view.relatedSessions';
	           	}else if(vm.annexaBoxObjectBook.book.bookType.bookElement == 'DOSSIER' ||  vm.annexaBoxObjectBook.book.bookType.bookElement == 'DOSSIER_DOCUMENT'){
	           		vm.title = 'global.books.view.relatedDossiers';
	           	}else if(vm.annexaBoxObjectBook.book.bookType.bookElement == 'SESSION'){
	           		vm.title = 'global.books.view.relatedDecrees';
	           	}
            }
        }]
    })
    .component('annexaBoxObjectBookElementListRegisters',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book-element-list-registers.html',
        require: {
        	annexaBoxObjectBookElementList: '^^annexaBoxObjectBookElementList'
        },
        controller:['$rootScope', '$filter', 'Language', 'BooksFactory', '$scope', 'GlobalDataFactory', 'TableFilterFactory', function($rootScope, $filter, Language, BooksFactory, $scope, GlobalDataFactory, TableFilterFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            this.$onInit = function() {
            	$scope.$on('reloadBookRegisterList', function (event, args) {
            		vm.tableDefinition.reloadInternalData(true, true);
            	});
            	
            	if(vm.annexaBoxObjectBookElementList && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book){
            		vm.filterRegisterBook = [
                        { id: 'entryNumber', type: 'text', order: 1, label: 'global.literals.number' },
                        { id: 'dataReg', type: 'dateRange', order: 2, label: 'global.reg.datatable.registerDate', callAux: true },
                        { id: 'dataPres', type: 'dateRange', order: 3, label: 'global.literals.presFrom', callAux: true },
                        { id: 'registerEntryChannel', type: 'select', order: 4, label: 'global.literals.channel', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryChannelsInput, addAll: true, nameProperty: Language.getActiveColumn() },
                        { id: 'extract', type: 'text', order: 5, label: 'global.literals.extract' }
                    ]

                    if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
                    	vm.filterRegisterBook.unshift({ id: 'registerEntryOffice', type: 'select', order: 0, label: 'global.literals.office', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryOffices, addAll: true, nameProperty: Language.getActiveColumn() });
                    }
            		vm.tableOrderProperties = {sortName: 'entryNumber', sort: [[0, 'asc']]};
            		
            		vm.getFilterCall = getFilterCall;
            		vm.getFilterCallAux = getFilterCallAux;
                    
            		function getFilterCall() {
            			var filterCall = TableFilterFactory.getFilterCall(vm.filterRegisterBook);
                        return filterCall;
                    };


                    function getFilterCallAux() {
                    	var filterCallAux = TableFilterFactory.getFilterCallAux(vm.filterRegisterBook);
                        filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        filterCallAux.langColumn = vm.languageColumn;
                        filterCallAux.bookId = vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.id;
                        filterCallAux.bookModule = 'BOOKS_REGISTER';
                        filterCallAux.bookSearch = 'LIST';
                        return filterCallAux;
                    };
                    
                    vm.columnsAux = [
                    	{ id: 'entryNumber', width: '110px', title: $filter('translate')('global.literals.number') },
                        { id: 'registerDate', width: '110px', column: new DateTimeColumn($filter,'global.reg.datatable.registerDate') },
                        { id: 'presentationDate', width: '90px', column: new DateColumn($filter,'global.literals.presFrom') },
                        { id: 'registerEntryChannel.'+ vm.languageColumn, width: '60px', title: $filter('translate')('global.literals.channel') },
                        { id: 'extract', title: $filter('translate')('global.literals.extract'), sortable: false },
                    ];
                    if(vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.state == 'DRAFT' && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.canEdit){
       				 vm.columnsAux.push({ id: 'id', width: '120px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.books.view.unrelated','$ctrl.annexaBoxObjectBookElementList.annexaBoxObjectBook.deleteElementToBook([data])', 'fa-trash')),sortable: false});
                    }
                    if($rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
                    	vm.columnsAux.unshift({ id: 'registerEntryOffice.acronym', width: '50px', title: $filter('translate')('global.literals.office') });
                    }

                   
                    vm.tableDefinition = {
                            id: 'dataTableElementListRegisters',
                            origin: 'reg',
                            objectType: 'RegisterEntry',
                            sortName: vm.tableOrderProperties.sortName,
                            sort: vm.tableOrderProperties.sort,
                            filterCall: vm.getFilterCall(),
                            filterCallAux: vm.getFilterCallAux(),
                            filterCallFunc: vm.getFilterCall,
                            filterCallAuxFunc: vm.getFilterCallAux,
                            columns: vm.columnsAux,
                            containerScope: $scope,
                            useTableObjects: true
                    }
            		
            	}              
            }
        }]
    })
    .component('annexaBoxObjectBookElementListDocuments',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book-element-list-documents.html',
        require: {
        	annexaBoxObjectBookElementList: '^^annexaBoxObjectBookElementList'
        },
        controller:['$rootScope', '$filter', 'Language', 'BooksFactory', '$scope', 'DccumentsFactory', 'GlobalDataFactory', 'TableFilterFactory', function($rootScope, $filter, Language, BooksFactory, $scope, DccumentsFactory, GlobalDataFactory, TableFilterFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            this.$onInit = function() {
            	$scope.$on('reloadBookDocumentList', function (event, args) {
            		vm.tableDefinition.reloadInternalData(true, true);
            	});
            	var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
            	
            	var docStatusRender = function(data, type, full, meta) {
                    var content = '';

                    if(DccumentsFactory.statuses && DccumentsFactory.statuses.length > 0) {
                        var status = $linq(DccumentsFactory.statuses).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                        if(status) {
                            content += '<div class="text-center">';
                            content += '    <span class="fa-stack" title=" ' + $filter('translate')('global.documentState.' + status.name) + '">';
                            content += '        <i class="fa fa-' + status.icon + ' text-' + status.style + ' text-lg fa-stack-1x"></i>';
                            content += '    </span>';
                            content += '</div>';
                        }
                    }

                    return content;
                }
            	
            	 var favoriteRender = function (data, type, full, meta) {
                     var starClasses = 'fa fa-star text-warn text-lg fa-stack-1x';

                     if(!data) {
                         starClasses = 'fa fa-star-o text-lg fa-stack-2x';
                     }
                     var content = '';
                     content += '<div class="text-center">';
                     content += '    <button class="md-btn p-a-0 md-flat md-btn__star" title="{{ \'global.literals.favorite\' | translate }}">';
                     content += '        <span class="sr-only">{{ \'global.literals.favorite\' | translate }}</span>';
                     content += '        <span class="fa-stack">';
                     content += '            <i class="' + starClasses + '"></i>';
                     content += '        </span>';
                     content += '    </button>';
                     content += '</div>';

                     return content;
                 }
            	 
            	 if(vm.annexaBoxObjectBookElementList && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book){
            		 var documentTypes = [];
            		 if(vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.bookType.attachDocumentTypes && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.bookType.attachDocumentTypes.length > 0){
	           			documentTypes = $linq(vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.bookType.attachDocumentTypes).select("x => x.documentType").toArray();
	           		 }else{
	           			documentTypes = $linq(GlobalDataFactory.documentTypes).where("x => x.isPublic").toArray();
	           		 }
            		 
            		 vm.filterDocumentBook = [
            			 	{ id: 'docStatus', type: 'select', order: 0, label: 'global.literals.status', dataType: 'LOCAL', data: $linq(GlobalDataFactory.documentStatuses).orderBy("x => x.orderStatus").toArray(), addAll: true, nameProperty: 'nameLiteral'},
            			 	{ id: 'type', type: 'select', order: 1, label: 'global.literals.type', dataType: 'LOCAL', data: documentTypes, addAll: true, nameProperty:vm.languageColumn },
            			 	{ id: 'code', type: 'text', order: 2, label: 'global.literals.code' },
            			 	{ id: 'name', type: 'text', order: 3, label: 'global.literals.title' },
            			 	{ id: 'createUser', type: 'text', order: 4, label: 'global.literals.user', callAux: true },
            	            { id: 'modifyDdate', type: 'dateRange', order: 5, label: 'global.literals.modifyDate', callAux: true }
            	        ]

            		 vm.tableOrderProperties = {sortName: 'createdDate', sort: [[0, 'asc']]};
            		
            		vm.getFilterCall = getFilterCall;
            		vm.getFilterCallAux = getFilterCallAux;
                    
            		function getFilterCall() {
                        var filterCall = TableFilterFactory.getFilterCall(vm.filterDocumentBook);
                        if(filterCall && filterCall.docStatus && filterCall.docStatus.id){
                        	filterCall.docStatus = filterCall.docStatus.id;
                        }
                        return filterCall;
                    };


                    function getFilterCallAux() {
                    	var filterCallAux = TableFilterFactory.getFilterCallAux(vm.filterDocumentBook);
                        filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        filterCallAux.langColumn = vm.languageColumn;
                        filterCallAux.bookId = vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.id;
                        filterCallAux.bookModule = 'BOOKS_DOCUMENTS';
                        filterCallAux.bookSearch = 'LIST';
                        return filterCallAux;
                    };
                    
                    vm.columnsAux = [
                    	{ id: 'createdDate', visible: false },
                        { id: 'docStatus', width: '53px', title: $filter('translate')('global.literals.status'), render: docStatusRender, sortable: false },
                        { id: 'favorite', width: '53px', title: $filter('translate')('global.literals.favorite'), render: favoriteRender, sortable: false },
                        { id: 'type', width: '40px', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
                        { id: 'id', width: '100px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.id; if(full.createdDate) { code = new Date(full.createdDate).getFullYear().toString().substr(-2) + full.id; } code;', true) },
                        { id: 'name', column: new UIRouterStateColumn($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[full.id]\'})', 'name', undefined, true)  },
                        { id: 'createUser', column: new UserColumn($filter, 'global.literals.user') },
                        { id: 'modifyDate', width: '140px', column: new DateTimeColumn($filter, 'global.literals.modifyDate') },
                        
                    ];
        			if(vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.state == 'DRAFT' && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.canEdit){
        				 vm.columnsAux.push({ id: 'id', width: '120px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.books.view.unrelated','$ctrl.annexaBoxObjectBookElementList.annexaBoxObjectBook.deleteElementToBook([data])', 'fa-trash')),sortable: false});
        			}
                    
                    vm.tableDefinition = {
                            id: 'dataTableElementListDocuments',
                            origin: 'doc',
                            objectType: 'Document',
                            sortName: vm.tableOrderProperties.sortName,
                            sort: vm.tableOrderProperties.sort,
                            filterCall: vm.getFilterCall(),
                            filterCallAux: vm.getFilterCallAux(),
                            filterCallFunc: vm.getFilterCall,
                            filterCallAuxFunc: vm.getFilterCallAux,
                            columns: vm.columnsAux,
                            containerScope: $scope,
                            useTableObjects:true
                    }
            	}              
            }
        }]
    })
    .component('annexaBoxObjectBookElementListDocumentSessions',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book-element-list-document-sessions.html',
        require: {
        	annexaBoxObjectBookElementList: '^^annexaBoxObjectBookElementList'
        },
        controller:['$rootScope', '$filter', 'Language', 'BooksFactory', '$scope', 'SecFactory', 'TableFilterFactory', 'GlobalDataFactory', function($rootScope, $filter, Language, BooksFactory, $scope, SecFactory, TableFilterFactory,GlobalDataFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            this.$onInit = function() {
            	$scope.$on('reloadBookSessionList', function (event, args) {
            		vm.tableDefinition.reloadInternalData(true, true);
            	});
            	
            	var sessionStatusRender = function(data, type, full, meta) {
                    var content = '';

                    if(SecFactory.sessionStates && SecFactory.sessionStates.length > 0) {
                        var state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                        if(state) {
                            content += '<div class="text-center">';
                            content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                            content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                            content += '    </span>';
                            content += '</div>';
                        }
                    }

                    return content;
                }
            	
            	if(vm.annexaBoxObjectBookElementList && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book){
            		vm.filterSessionBook = [
	            		{ id: 'state', type: 'select-multiple', order: 0, label: 'global.literals.status', dataType: 'LOCAL', data: SecFactory.sessionStates, addAll: true, nameProperty: 'name', callAux: true},
	                    { id: 'organ', type: 'select', order: 1, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: GlobalDataFactory.organs, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true},
	                    { id: 'sessionType', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: GlobalDataFactory.sessionTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
	                    { id: 'sessionDateFirstConvene', type: 'dateRange', order: 3, label: 'global.sec.literals.sessionDate', callAux: true },
	                    { id: 'sessionNumber', type: 'text', order: 4, label: 'global.sec.literals.sessionNumber' }
                    ];
            		vm.tableOrderProperties = {sortName: 'sessionDateFirstConvene', sort: [[4,'desc']]};
            		
            		vm.getFilterCall = getFilterCall;
            		vm.getFilterCallAux = getFilterCallAux;
                    
            		function getFilterCall() {
            			var filterCall = TableFilterFactory.getFilterCall(vm.filterSessionBook);
                        return filterCall;
                    };


                    function getFilterCallAux() {
                    	var filterCallAux = TableFilterFactory.getFilterCallAux(vm.filterSessionBook);
                        filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        filterCallAux.langColumn = vm.languageColumn;
                        filterCallAux.bookId = vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.id;
                        filterCallAux.bookModule = 'BOOKS_SESSIONS';
                        filterCallAux.bookSearch = 'LIST';
                        return filterCallAux;
                    };
                    
                    vm.columnsAux = [
                    	{ id: 'createdDate', visible: false },
                        { id: 'state', width: '5%', title: $filter('translate')('global.literals.status'), render: sessionStatusRender, sortable: false},
                        { id: 'organ', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.organ', vm.languageColumn) },
                        { id: 'sessionType', width: '15%', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.type', vm.languageColumn) },
                        { id: 'sessionDateFirstConvene', width: '10%', column: new DateTimeColumn($filter, 'global.sec.literals.sessionDate')},
                        { id: 'sessionNumber', width: '10%', column: new TextColumn($filter, 'global.sec.literals.sessionNumber')}
                    ];
                    if(vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.state == 'DRAFT' && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.canEdit){
       				 vm.columnsAux.push({ id: 'id', width: '120px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.books.view.unrelated','$ctrl.annexaBoxObjectBookElementList.annexaBoxObjectBook.deleteElementToBook([data])', 'fa-trash')),sortable: false});
                    }
                   
                    vm.tableDefinition = {
                            id: 'dataTableElementListDocumentsSessions',
                            origin: 'sec',
                            objectType: 'Session',
                            sortName: vm.tableOrderProperties.sortName,
                            sort: vm.tableOrderProperties.sort,
                            filterCall: vm.getFilterCall(),
                            filterCallAux: vm.getFilterCallAux(),
                            filterCallFunc: vm.getFilterCall,
                            filterCallAuxFunc: vm.getFilterCallAux,
                            columns: vm.columnsAux,
                            containerScope: $scope,
                            useTableObjects:true
                    }
            		
            	}              
            }
        }]
    })
    .component('annexaBoxObjectBookElementListDossiers',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book-element-list-dossiers.html',
        require: {
        	annexaBoxObjectBookElementList: '^^annexaBoxObjectBookElementList'
        },
        controller:['$rootScope', '$filter', 'Language', 'BooksFactory', '$scope', 'TableFilterFactory', function($rootScope, $filter, Language, BooksFactory, $scope, TableFilterFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            this.$onInit = function() {
            	$scope.$on('reloadBookDossierList', function (event, args) {
            		vm.tableDefinition.reloadInternalData(true, true);
            	});
            	
            	if(vm.annexaBoxObjectBookElementList && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book){
            		vm.filterDossiersBook = [
                        { id: 'dossierNumber', type: 'text', order: 0, label: 'global.literals.code', callAux: true},
                        { id: 'procedure', type: 'text', order: 1, label: 'global.literals.procedure', callAux: true},
                        { id: 'subject', type: 'text', order: 2, label: 'global.literals.dossier', callAux: true}
                    ];
            		vm.tableOrderProperties = {sortName: 'dossierNumber', sort: [[0, 'asc']]};
            		
            		vm.getFilterCall = getFilterCall;
            		vm.getFilterCallAux = getFilterCallAux;
                    
            		function getFilterCall() {
            			var filterCall = TableFilterFactory.getFilterCall(vm.filterDossiersBook);
                        return filterCall;
                    };


                    function getFilterCallAux() {
                    	var filterCallAux = TableFilterFactory.getFilterCallAux(vm.filterDossiersBook);
                        filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        filterCallAux.langColumn = vm.languageColumn;
                        filterCallAux.filterOrigin = 'dossierAdvancedView';
                        filterCallAux.bookId = vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.id;
                        filterCallAux.bookModule = 'BOOKS_DOSSIERS';
                        filterCallAux.bookSearch = 'LIST';
                        return filterCallAux;
                    };
                    
                    vm.columnsAux = [
                    	{ id: 'dossierNumber', title: $filter('translate')('global.literals.code') },
                        { id: 'procedure.' + vm.languageColumn, title: $filter('translate')('global.literals.procedure') },
                        { id: 'subject', title: $filter('translate')('global.literals.dossier') }
                    ];
                    if(vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.state == 'DRAFT' && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.canEdit){
       				 vm.columnsAux.push({ id: 'id', width: '120px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.books.view.unrelated','$ctrl.annexaBoxObjectBookElementList.annexaBoxObjectBook.deleteElementToBook([data])', 'fa-trash')),sortable: false});
                    }
                   
            		vm.tableDefinition = {
                            id: 'dataTableElementListDossiers',
                            origin: 'tram',
                            objectType: 'Dossier',
                            sortName: vm.tableOrderProperties.sortName,
                            sort: vm.tableOrderProperties.sort,
                            filterCall: vm.getFilterCall(),
                            filterCallAux: vm.getFilterCallAux(),
                            filterCallFunc: vm.getFilterCall,
                            filterCallAuxFunc: vm.getFilterCallAux,
                            columns: vm.columnsAux,
                            containerScope: $scope
                    }
            		
            		
            	}              
            }
        }]
    })
    .component('annexaBoxObjectBookElementListDecrees',{
        templateUrl: './components/books/annexa-box-object-book/annexa-box-object-book-element-list-decrees.html',
        require: {
        	annexaBoxObjectBookElementList: '^^annexaBoxObjectBookElementList'
        },
        controller:['$rootScope', '$filter', 'Language', 'BooksFactory', '$scope', 'SecFactory', 'TableFilterFactory', 'GlobalDataFactory', function($rootScope, $filter, Language, BooksFactory, $scope, SecFactory, TableFilterFactory,GlobalDataFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            this.$onInit = function() {
            	$scope.$on('reloadBookDecreeList', function (event, args) {
            		vm.tableDefinition.reloadInternalData(true, true);
            	});
            	vm.decreeStates = angular.copy(SecFactory.decreeStates);
                vm.decreeStates.unshift({id: '', icon: 'minus', style: 'text-black', name: $filter('translate')('global.literals.all'), orderStatus: 0});
                vm.proposalTypes = angular.copy(GlobalDataFactory.proposalTypes);
                vm.proposalTypes = $linq(vm.proposalTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
                vm.proposalTypes.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
                
            	var decStatusRender = function(data, type, full, meta) {
                    var content = '';
                    if(SecFactory.decreeStates && SecFactory.decreeStates.length > 0) {
                        var state = $linq(SecFactory.decreeStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");
                        if(state) {
                            content += '<div class="text-center">';
                            content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                            content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                            content += '    </span>';
                            content += '</div>';
                        }
                    }
                    return content;
                }
            	
            	if(vm.annexaBoxObjectBookElementList && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book){
            		vm.filterDecreeBook = [
                    	{ id: 'state', type: 'select', order: 2, label: 'global.literals.state', dataType: 'LOCAL', data: vm.decreeStates, addAll: true, nameProperty: 'name', callAux: true},
                    	{ id: 'decreeNumber', type: 'text', order: 0, label: 'global.sec.literals.decreeNumberAbrv'},
                    	{ id: 'proposalType', type: 'select', order: 11, label: 'global.sec.literals.proposalType', data:vm.proposalTypes , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
                        { id: 'proposalNumber', type: 'text', order: 1, label: 'global.sec.literals.proposalNumber', callAux: true },
                        { id: 'decreeDate', type: 'dateRange', order: 2, label: 'global.sec.literals.decreeDate', callAux: true },
                        { id: 'extract', type: 'text', order: 3, label: 'global.literals.extract' }
                    ];
             		vm.tableOrderProperties = {sortName: 'decreeDate', sort: [[2,'desc']]};
            		
            		vm.getFilterCall = getFilterCall; 
            		vm.getFilterCallAux = getFilterCallAux;
                    
            		function getFilterCall() {
            			var filterCall = TableFilterFactory.getFilterCall(vm.filterDecreeBook);
                        return filterCall;
                    };


                    function getFilterCallAux() {
                    	var filterCallAux = TableFilterFactory.getFilterCallAux(vm.filterDecreeBook);
                        filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                        filterCallAux.langColumn = vm.languageColumn;
                        filterCallAux.bookId = vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.id;
                        filterCallAux.bookModule = 'BOOKS_DECREES';
                        filterCallAux.bookSearch = 'LIST';
                        return filterCallAux;
                    };
                    
                    vm.columnsAux = [
                    	{ id: 'status', width: '1%', title: '', render: decStatusRender, sortable: false },
                        { id: 'proposal.proposalType', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.proposalType', Language.getActiveColumn()) },
                    	{ id: 'decreeNumber', column: new TextColumn($filter, 'global.sec.literals.decreeNumberAbrv') },
                    	{ id: 'decreeDate', width: '12%', column:  new DateColumn($filter, 'global.sec.literals.decreeDate') },
                    	{ id: 'extract', column:  new TextColumn($filter, 'global.literals.extract') },
                        { id: 'proposal.proposalNumber', column: new TextColumn($filter, 'global.sec.literals.proposalNumber') }
                    ];
                    if(vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.book.state == 'DRAFT' && vm.annexaBoxObjectBookElementList.annexaBoxObjectBook.canEdit){
       				 vm.columnsAux.push({ id: 'id', width: '120px', className: 'text-center', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.books.view.unrelated','$ctrl.annexaBoxObjectBookElementList.annexaBoxObjectBook.deleteElementToBook([data])', 'fa-trash')),sortable: false});
                    }
                   
                    vm.tableDefinition = {
                            id: 'dataTableElementListDecrees',
                            origin: 'sec',
                            objectType: 'Decree',
                            sortName: vm.tableOrderProperties.sortName,
                            sort: vm.tableOrderProperties.sort,
                            filterCall: vm.getFilterCall(),
                            filterCallAux: vm.getFilterCallAux(),
                            filterCallFunc: vm.getFilterCall,
                            filterCallAuxFunc: vm.getFilterCallAux,
                            columns: vm.columnsAux,
                            containerScope: $scope,
                            useTableObjects:true
                    }
            		
            	}              
            }
        }]
    })


    



    